<template>
  <div id="announcementNotificationContainer" class="position-relative"></div>
</template>

<script setup>
onMounted(() => {
  const nVersion = Date.now();

  if (!window.AnnouncementLib) {
    const preloadNotificationStyle = document.createElement('link');
    preloadNotificationStyle.href =
      '/notifications/css/announcement-component-styles.css?v=' + nVersion;
    preloadNotificationStyle.rel = 'stylesheet';
    document.head.appendChild(preloadNotificationStyle);

    const preloadNotification = document.createElement('script');
    preloadNotification.src =
      '/notifications/js/announcement-component.js?v=' + nVersion;
    preloadNotification.defer = 'true';
    document.head.appendChild(preloadNotification);

    let stylesLoaded = false;
    let scriptLoaded = false;

    preloadNotificationStyle.onload = () => {
      stylesLoaded = true;
      loadAnnouncment();
    };

    preloadNotification.onload = () => {
      scriptLoaded = true;
      loadAnnouncment();
    };

    const loadAnnouncment = () => {
      if (scriptLoaded && stylesLoaded) {
        useAnnouncement().loadAnnouncements();
      }
    };
  } else {
    useAnnouncement().loadAnnouncements();
  }
});
</script>

<style lang="scss" scoped>
#announcementNotificationContainer {
  width: 100%;
}

.annoucements-card-body {
  padding: 8px;
  .trim-message-text {
    margin-left: 8px;
  }
}
</style>
