export const useAnnouncement = () => {
  // instance of Announcement Library
  let announcementLib: any;

  const loadAnnouncements = () => {
    if (
      window &&
      typeof window['AnnouncementLib'] !== 'undefined' &&
      window['AnnouncementLib']
    ) {
      initialiseAnnouncementComponent();
    } else {
      //Listen to notification custom event
      const handler = () => {
        initialiseAnnouncementComponent();
        window.removeEventListener(
          'cambridgeOne.notificationComponentLoaded',
          handler
        );
      };
      window.addEventListener(
        'cambridgeOne.notificationComponentLoaded',
        handler
      );
    }
  };

  const initialiseAnnouncementComponent = () => {
    setTimeout(() => {
      getAnnouncementInstance();
      announcementLib.initAnnoucement('announcementNotificationContainer');
    }, 0);
  };

  const getAnnouncementInstance = () => {
    if (!announcementLib) {
      announcementLib = new window['AnnouncementLib'].AnnouncementComponent();
      console.log(announcementLib);
    }
  };

  return {
    loadAnnouncements
  };
};
